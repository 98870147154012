<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t('viewShipownerLicense', { number: statement.number || statement.other_number }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')") (ID: {{ statement.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(
      v-if="statement.behavior.viewInfoBlock"
      :statement="statement")

    FormEdit(
      v-else-if="statement.behavior.viewEditBlock"
      :statement="statement")

    EditStatus(
      v-else-if="statement.behavior.viewEditStatusBlock"
      :statement="statement")

    ViewPhotoList(
      v-else-if="statement.behavior?.viewFilesBlock"
      :files="statement.photo"
      :sailorDocument="statement"
      :documentType="type"
      :deleteFile="removeFileNavigatorCertificates"
      )
</template>

<script>
import { back, copyToClipboard, deleteConfirmation, viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorNavigatorCertificateDocument',
  components: {
    Info: () => import('./Info.vue'),
    FormEdit: () => import('./Form.vue'),
    EditStatus: () => import('.//EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'navigatorCertificates',
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.statement, 'viewEditBlock'),
          checkAccess: () => checkAccess('navigatorCertificates', 'edit', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.statement, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('navigatorCertificates', 'editStatus', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess('navigatorCertificates', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('navigatorCertificates', 'delete', this.statement),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => back('qualification-navigator'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['statementByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      statement: state => state.sailor.navigatorCertificateById
    })
  },
  mounted () {
    this.getNavigatorCertificateById({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['removeNavigatorCertificates', 'getNavigatorCertificateById', 'removeFileNavigatorCertificates']),
    deleteDocument () {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const response = await this.removeNavigatorCertificates({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) {
            this.$notification.success('deletedDocument')
            back('qualification-navigator')
          }
        }
      })
    }
  }
}

</script>
